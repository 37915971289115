<template>
  <div>
    <div class="tw--mx-2 tw-pt-4 tw-flex sm:tw-flex-row-reverse tw-flex-wrap">
      <div class="tw-px-2 tw-w-full md:tw-w-1/3 xl:tw-w-1/5">
        <div class="card">
          <div class="tw-mb-8 tw-text-2xl tw-font-semibold">
            Filters
          </div>
          <div class="sm:tw-flex md:tw-block sm:tw--mx-2 md:tw-mx-0">
            <div
              class="tw-w-full sm:tw-w-1/2 md:tw-w-full sm:tw-px-2 md:tw-px-0"
            >
              <div class="form-group">
                <label class="form-label" for="from_date">From</label>
                <SingleDatePicker
                  id="from_date"
                  v-model="reportData.fromDate"
                  :format="dateFormatWithSlashByLocale"
                  :placeholder="dateFormatWithSlashByLocale"
                  data-vv-as="from date"
                  name="from_date"
                  data-cy="from-date"
                  class="form-control"
                  type="text"
                  autocomplete="off"
                  readonly
                  emits-moment
                />
              </div>
              <div class="form-group">
                <label class="form-label" for="to_date">To</label>
                <SingleDatePicker
                  id="to_date"
                  v-model="reportData.toDate"
                  :format="dateFormatWithSlashByLocale"
                  :placeholder="dateFormatWithSlashByLocale"
                  data-vv-as="to date"
                  name="to_date"
                  data-cy="to-date"
                  class="form-control"
                  autocomplete="off"
                  type="text"
                  readonly
                  emits-moment
                />
              </div>
              <div class="form-group">
                <label class="form-label" for="overtime_status">Status</label>
                <div class="tw-relative tw-w-full">
                  <OvertimeStatusPicker
                    id="overtime_status"
                    v-model="reportData.selectedOvertimeStatuses"
                    :placeholder="'All'"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="form-label" for="department">Department</label>
                <div class="tw-relative tw-w-full">
                  <DepartmentPicker
                    id="department"
                    v-model="reportData.selectedDepartment"
                    :options="selectableDepartments"
                    @input="getEmployments"
                  />
                </div>
              </div>
            </div>
            <div
              class="tw-w-full sm:tw-w-1/2 md:tw-w-full sm:tw-px-2 md:tw-px-0"
            >
              <div class="form-group">
                <label class="form-label" for="employment">Employee</label>
                <div class="tw-relative tw-w-full">
                  <EmploymentPicker
                    id="employment"
                    v-model="reportData.selectedEmployment"
                    :options="selectableEmployments"
                  />
                </div>
              </div>
              <div class="form-group tw-mb-0">
                <label class="form-label" for="report-type">Report Type</label>
                <div class="tw-relative tw-w-full">
                  <ReportTypePicker
                    id="report-type"
                    v-model="reportData.reportType"
                    :options="selectableReportTypes"
                    :reduce="option => option.id"
                  />
                </div>
              </div>
              <div class="tw-flex tw-justify-start">
                <UpgradePlanPopup
                  action-message="download reports"
                  :feature="Feature.TimeOffInLieu"
                  :is-active="!companyHasToilFeature"
                >
                  <SpinnerButton
                    :disabled="!companyHasToilFeature || downloadingOvertime"
                    data-cy="download-xl"
                    class="tw-mt-6"
                    @click="downloadOvertimeReport"
                  >
                    Download
                  </SpinnerButton>
                </UpgradePlanPopup>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tw-px-2 tw-w-full md:tw-w-2/3 xl:tw-w-4/5">
        <div class="card">
          <div v-if="reportData.reportType === 'detail-report'">
            <OvertimeDetailReportTable
              :overtimes="paginatedOvertime.data"
              :loading="fetchingOvertime"
            />
          </div>
          <div v-else>
            <OvertimeSummaryReportTable
              :summarised-overtimes="paginatedOvertime.data"
              :loading="fetchingOvertime"
            />
          </div>
        </div>

        <div class="paginate-wrapper">
          <Pagination
            :current-page="paginatedOvertime.current_page"
            :page-count="pageCount"
            :click-handler="fetchOvertime"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileSaver from 'file-saver'
import moment from 'moment-timezone'
import FormatDate from '@/mixins/FormatDate'
import ValidatesForm from '@/mixins/ValidatesForm'
import HandleReportData from '@/mixins/HandleReportData'
import Pagination from '@/components/pagination/Pagination'
import DepartmentPicker from '@/components/pickers/DepartmentPicker'
import EmploymentPicker from '@/components/pickers/EmploymentPicker'
import ReportTypePicker from '@/components/pickers/ReportTypePicker'
import OvertimeSummaryReportTable from '@/components/reports/overtime/OvertimeSummaryReportTable'
import OvertimeDetailReportTable from '@/components/reports/overtime/OvertimeDetailReportTable'
import SpinnerButton from '@/components/SpinnerButton'
import OvertimeStatusPicker from '@/components/pickers/OvertimeStatusPicker'
import Overtime from '@/api/reporting/Overtime'
import { HTTP_PAYMENT_REQUIRED } from '@/plugins/http'
import Feature from '@/models/Billing/Feature'
import UpgradePlanPopup from '@/components/UpgradePlanPopup'

const SingleDatePicker = () => import('@/components/SingleDatePicker')

const PAGINATED_OVERTIME = {
  data: [],
  total: 0,
  per_page: 0,
  current_page: 1,
}

export default {
  name: 'OvertimeReport',

  components: {
    UpgradePlanPopup,
    OvertimeStatusPicker,
    SpinnerButton,
    Pagination,
    SingleDatePicker,
    DepartmentPicker,
    EmploymentPicker,
    ReportTypePicker,
    OvertimeDetailReportTable,
    OvertimeSummaryReportTable,
  },

  mixins: [FormatDate, HandleReportData, ValidatesForm],

  data() {
    return {
      departments: [],
      employments: [],
      reportData: {
        reportType: 'detail-report',
        selectedDepartment: '',
        selectedEmployment: '',
        toDate: moment(),
        fromDate: moment().startOf('month'),
        selectedOvertimeStatuses: [],
      },
      fetchingOvertime: false,
      downloadingOvertime: false,
      paginatedOvertime: PAGINATED_OVERTIME,
    }
  },

  computed: {
    Feature() {
      return Feature
    },

    companyHasToilFeature() {
      return this.activeCompany.hasFeature(Feature.TimeOffInLieu)
    },

    pageCount() {
      return Math.ceil(
        this.paginatedOvertime.total / this.paginatedOvertime.per_page
      )
    },

    formattedDate() {
      return (
        this.formatDateFromIsoToDayReadableShortDayNumberShortMonthNumberYearNumber(
          this.reportData.fromDate
        ) +
        '_' +
        this.formatDateFromIsoToDayReadableShortDayNumberShortMonthNumberYearNumber(
          this.reportData.toDate
        )
      )
    },

    dateFormatWithSlashByLocale() {
      return this.getFormatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash()
    },

    selectableEmployments() {
      return [this.allOption, ...this.employments]
    },

    selectableReportTypes() {
      return [
        {
          id: 'detail-report',
          name: 'Detail',
        },
        {
          id: 'summary-report',
          name: 'Summary',
        },
      ]
    },
  },

  watch: {
    reportData: {
      deep: true,
      handler() {
        this.paginatedOvertime = PAGINATED_OVERTIME
        this.fetchOvertime()
      },
    },

    '$route.query.company': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.reportData = {
          ...this.reportData,
          selectedDepartment: this.allOption,
          selectedEmployment: this.allOption,
          toDate: moment(),
          fromDate: moment().startOf('month'),
        }
        this.fetchDepartments()
        this.fetchEmployments()
      },
    },
  },

  methods: {
    getEmployments() {
      this.reportData.selectedEmployment = this.allOption

      if (this.isAdmin) this.fetchEmployments()
      if (this.isNonAdminApprover) this.getSubordinates()
    },

    async fetchEmployments() {
      this.loading = true

      try {
        const { data } = await this.$http.get('employments', {
          params: {
            company_id: this.activeCompany.id,
            department: this.reportData.selectedDepartment.id,
          },
        })

        this.employments = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    async fetchOvertime(page) {
      if (!this.companyHasToilFeature) {
        return
      }

      this.fetchingOvertime = true

      try {
        const { data } = await Overtime.getReport({
          company_id: this.activeCompany.id,
          owner: this.reportData.selectedEmployment.id,
          department: this.reportData.selectedDepartment.id,
          within:
            this.reportData.fromDate.startOf('day').format() +
            ',' +
            this.reportData.toDate.endOf('day').format(),
          report_type: this.reportData.reportType,
          page: page || this.paginatedOvertime.current_page,
          status: this.joinToString(this.reportData.selectedOvertimeStatuses),
        })

        this.paginatedOvertime = data
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.fetchingOvertime = false
    },

    async downloadOvertimeReport() {
      this.downloadingOvertime = true

      const fromDate = this.reportData.fromDate.startOf('day').format()
      const toDate = this.reportData.toDate.endOf('day').format()

      try {
        const { data } = await Overtime.downloadReport({
          company_id: this.activeCompany.id,
          employee: this.reportData.selectedEmployment.id,
          department: this.reportData.selectedDepartment.id,
          within: fromDate + ',' + toDate,
          report_type: this.reportData.reportType,
          status: this.joinToString(this.reportData.selectedOvertimeStatuses),
        })

        FileSaver.saveAs(
          new Blob([data]),
          `overtime-${this.reportData.reportType}-${this.formattedDate}.xlsx`
        )
      } catch ({ response }) {
        if (response.status === HTTP_PAYMENT_REQUIRED) {
          response.data
            .text()
            .then(response => this.error(JSON.parse(response).message))

          return
        }

        this.validateFromResponse(response)
      }

      this.downloadingOvertime = false
    },

    joinToString(items) {
      return items.length ? items.map(item => item.value).join(',') : null
    },
  },
}
</script>

<style>
.paginate-wrapper {
  display: flex;
  justify-content: flex-end;
}
</style>
