<template>
  <div
    v-if="plan"
    class="tw-inline-flex tw-border tw-rounded-lg tw-font-semibold tw-text-thin tw-cursor-default"
    :style="{ background: backgroundColour, color: textColour }"
  >
    <SvgIcon
      class="tw-w-5 tw-h-5"
      :style="iconStyles"
      :name="planIcon"
    ></SvgIcon>
    <span v-if="!iconOnly" class="tw-pl-1">{{ plan.displayName }}</span>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'LowestCostPlanLabel',

  props: {
    feature: {
      type: String,
      required: true,
    },

    iconOnly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('billing', ['plans']),

    iconStyles() {
      return {
        background: this.backgroundColour,
        fill: this.textColour,
        stroke: this.textColour,
      }
    },

    plan() {
      if (this.companyHasFeature) {
        return null
      }

      return this.plans.findSmallestPlanHasFeature(this.feature)
    },

    companyHasFeature() {
      return this.activeCompany.hasFeature(this.feature)
    },

    backgroundColour() {
      const planBackgroundColorMapper = {
        essential: '#f0d1e4',
      }

      return planBackgroundColorMapper[this.plan.name]
    },

    textColour() {
      const planTextColorMapper = {
        essential: '#6b2450',
      }

      return planTextColorMapper[this.plan.name]
    },

    planIcon() {
      const planIconMapper = {
        essential: 'puzzle-piece',
      }

      return planIconMapper[this.plan.name]
    },
  },
}
</script>
