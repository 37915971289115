<template>
  <div>
    <div class="tw-w-full">
      <Portal to="action-bar">
        <div
          class="page-header tw-flex tw-items-center tw-justify-between tw-flex-wrap md:tw-flex-no-wrap tw-px-2"
          :class="{ 'tw-mt-4': !emailVerified || !hasProperSubscription }"
        >
          <div class="tw-w-full sm:tw-w-4/5 lg:tw-w-3/4 xl:tw-w-4/5 tw-px-2">
            <div class="tw-flex tw-flex-wrap tw--mx-2">
              <div
                v-if="isAdmin || isApprover"
                class="tw-px-2 tw-w-full sm:tw-w-full md:tw-w-1/3 lg:tw-w-1/3 xl:tw-w-1/4"
              >
                <div
                  class="tw-w-full sm:tw-w-1/2 md:tw-w-full tw-mb-4 md:tw-mb-0"
                >
                  <VSelect
                    v-if="employee"
                    :value="employee"
                    :options="availableEmployments"
                    :multiple="false"
                    :allow-empty="false"
                    :show-labels="false"
                    :max-height="180"
                    class="user-v-select multiselect-small tw-rounded-lg tw-shadow-lg"
                    placeholder="Select an employee"
                    label="full_name"
                    data-cy="select-employee"
                    track-by="id"
                    @input="updateEmployee"
                  >
                    <template #singleLabel="props">
                      <div
                        class="tw-flex tw-justify-start tw-items-center tw-content-center"
                      >
                        <div
                          class="tw-text-base tw-font-normal"
                          data-cy="selected-employee-name"
                        >
                          {{ props.option.full_name }}
                        </div>
                      </div>
                    </template>
                    <template #option="props">
                      <div
                        class="tw-flex tw-justify-start tw-items-center tw-content-center"
                      >
                        <UserAvatar :user="props.option" class="tw-mr-2" />
                        <div
                          class="tw-text-base tw-font-normal"
                          data-cy="drpdown-employee-name"
                        >
                          {{ props.option.full_name }}
                        </div>
                      </div>
                    </template>
                    <template #noResult>
                      <span>No items found.</span>
                    </template>
                  </VSelect>
                </div>
              </div>

              <div
                class="tw-px-2 tw-w-full sm:tw-w-full md:tw-w-1/3 lg:tw-w-1/3 xl:tw-w-1/4"
              >
                <div class="">
                  <CalendarPicker
                    v-if="selectedCalendar"
                    v-model="selectedCalendar"
                    :calendars="customCalendars"
                    class="tw-w-full sm:tw-w-1/2 md:tw-w-full tw-mb-4 md:tw-mb-0 tw-rounded-lg tw-shadow-lg"
                  />
                </div>
              </div>

              <div
                class="tw-px-2 tw-w-full sm:tw-w-full md:tw-w-1/3 lg:tw-w-1/3 xl:tw-w-1/4"
              >
                <div v-if="hasCustomCalendars">
                  <div
                    class="tw-relative tw-w-full sm:tw-w-1/2 md:tw-w-full tw-mb-4 md:tw-mb-0"
                  >
                    <VSelect
                      v-model="view"
                      :options="selectableViews"
                      :multiple="false"
                      :show-labels="false"
                      :allow-empty="false"
                      :max-height="180"
                      track-by="id"
                      label="name"
                      data-cy="myleaves-views"
                      class="tw-shadow-lg"
                      placeholder="Select a view"
                    >
                    </VSelect>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="tw-w-full sm:tw-w-1/5 lg:tw-w-1/4 xl:tw-w-1/5 tw-text-right tw-px-2 tw-flex tw-justify-end"
          >
            <SpinnerButton
              v-if="hasCustomCalendars"
              data-cy="book-leave-btn"
              type="button"
              @click="() => showRequestForm()"
            >
              <span class="tw-uppercase tw-text-thin tw-font-normal">Book</span>
            </SpinnerButton>
          </div>
        </div>
      </Portal>

      <div
        v-if="selectedCalendar || isActiveEmployment"
        class="tw-flex lg:tw-flex-row-reverse tw-flex-wrap tw-mb-6 tw--mx-2"
      >
        <div class="tw-w-full lg:tw-w-1/4 xl:tw-w-1/5 tw-px-2">
          <div
            class="tw-flex tw-flex-wrap lg:tw-flex-no-wrap lg:tw-flex-col sm:tw--mx-2"
          >
            <template v-if="hasLeave">
              <LeaveSummary :data-leave="leaves" />
            </template>

            <template v-if="selectedCalendar && employmentAllowances?.length()">
              <LeaveAllowance
                v-for="(summary, index) in orderedLeaveAllowances"
                :key="index"
                :summary="summary"
                :selected-employment="employee"
                :selected-calendar="selectedCalendar"
                class="tw-mb-4 sm:tw-px-2"
                data-cy="leave-summary"
                @show-employee-allowance="showEmployeeAllowance"
              />
            </template>

            <div
              v-if="isActiveEmployment && hasCustomCalendars"
              class="tw-w-full sm:tw-w-1/2 md:tw-w-1/3 lg:tw-w-full tw-mb-8 sm:tw-px-2"
            >
              <div class="card tw-mb-0">
                <div class="tw-mb-5 tw-text-xl tw-font-semibold">
                  Options
                </div>
                <div class="tw-mb-3">
                  <button
                    class="btn btn-link tw-p-0"
                    type="button"
                    @click="showCalendarIntegration"
                  >
                    Add to regular calendar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tw-w-full lg:tw-w-3/4 xl:tw-w-4/5 tw-px-2">
          <div v-if="hasCustomCalendars" class="tw-w-full">
            <div
              v-if="view.id === 'calendar'"
              class="tw-flex tw-flex-wrap tw--mx-2"
              data-cy="leave-calendar"
            >
              <MyLeaveCalendar
                v-for="(month, index) in months"
                :key="selectedCalendar.id + month.format('-MM-YYYY')"
                :month="month"
                :month-number="index + 1"
                :employee="employee"
                @create-request="createRequest"
                @show-overtime="showOvertimeDetailsPanel"
                @show-leave="showLeaveDetailsPanel"
              />
            </div>
            <div
              v-if="view.id === 'list'"
              class="tw-flex tw-flex-wrap"
              data-cy="leave-calendar"
            >
              <MyLeaveListTable
                :data-leaves="leaves"
                :calendar="selectedCalendar"
                @show-leave="showLeaveDetailsPanel"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="calendarsFetched && employeeFetched && !hasCustomCalendars"
        class="tw-px-2 tw-text-center tw-flex tw-items-center tw-justify-center tw-h-full"
      >
        <span class="tw-my-4 tw-text-gray-500">
          <SvgIcon name="calendar-myleave" class="tw-h-16" />
          <h2 class="tw-mt-4 tw-text-black">
            {{ calendarsNotAvailableMessage }}
          </h2>
          <h4 class="tw-mt-3 tw-text-gray-500">
            {{ contactAdministratorMessage }}
          </h4>
        </span>
      </div>
    </div>

    <Modal
      v-if="isAdmin"
      id="employee-allowance-form"
      :classes="[
        'tw-shadow-md',
        'tw-bg-white',
        'tw-rounded-lg',
        'modal-overflow-visible',
        allowanceModalClass,
      ]"
      name="employee-allowance-form"
      data-cy="employee-allowance-form"
      width="95%"
      height="auto"
      adaptive
      scrollable
    >
      <div class="modal-header">
        <div class="tw-flex tw-justify-between">
          <div>
            <p class="modal-title">Allowance</p>
          </div>
          <div>
            <button
              class="modal-close"
              data-cy="emp-allowance-close"
              @click="$modal.hide('employee-allowance-form')"
            >
              <SvgIcon name="close" class="tw-w-4 tw-h-4" />
            </button>
          </div>
        </div>
      </div>

      <EmployeeAllowance
        v-if="selectedCalendar"
        :current-calendar="selectedCalendar"
        :employment="employee"
        :calendars="customCalendars"
        :current-allowance-type="currentAllowanceType"
        :hide-carried-to-next-allowance="true"
        @show-proration-modal="setProrationData"
        @employment-allowance-updated="fetchEmploymentsAndLeaveAllowances"
      />
    </Modal>

    <Modal
      :classes="[
        'modal-overflow-visible',
        'tw-shadow-md',
        'tw-bg-white',
        'tw-rounded-lg',
      ]"
      :max-width="800"
      name="employee-allowance-proration"
      width="95%"
      height="auto"
      adaptive
      scrollable
      :click-to-close="false"
      @closed="prorationModalClosed"
    >
      <ProrateAllowanceModal
        v-if="canShowProrationModal"
        :employment="employee"
        :start-date="dataProrate.startDate"
        :start-date-changed="dataProrate.startDateChanged"
        :start-calendar="dataProrate.startCalendar"
        :end-date="dataProrate.endDate"
        :end-date-changed="dataProrate.endDateChanged"
        :end-calendar="dataProrate.endCalendar"
        @close-proration-modal="closeProrationModal"
        @employment-allowance-updated="dataProrate.allowanceUpdated = true"
      />
    </Modal>

    <Modal
      :classes="[
        'tw-shadow-md',
        'tw-bg-white',
        'tw-rounded-lg',
        'modal-overflow-visible',
      ]"
      :max-width="520"
      name="modal-cal-integration"
      width="95%"
      height="auto"
      adaptive
      scrollable
      @before-close="onCloseCalendarIntegrationModal"
    >
      <div class="modal-header">
        <div class="tw-flex tw-justify-between">
          <div>
            <p class="modal-title">
              Calendar Integrations
            </p>
          </div>
          <div>
            <button class="modal-close" @click="hideCalendarIntegration">
              <SvgIcon name="close" class="tw-w-4 tw-h-4" />
            </button>
          </div>
        </div>
      </div>

      <div class="tw-mt-3 tw-p-3">
        <div class="tw-w-full tw-px-3 tw-mb-3">
          <div class="tw-mb-2 tw-text-xl tw-font-semibold">
            Connect to Google or Outlook Calendar
          </div>
          <p class="tw-mb-6">
            Show your leave requests instantly in your Google or Outlook
            Calendar
          </p>
          <CalendarServices
            :calendar-services="calendarServices"
            @disconnected="disconnectExternalCalendar"
            @synchronization-created="handleSynchronizationCreated"
          />
        </div>
        <form class="tw-w-full tw-mt-6 tw-px-3">
          <div class="tw-mb-2 tw-text-xl tw-font-semibold">
            Subscribe to calendar feeds
          </div>
          <p class="tw-mb-6">
            Show leave requests in an external calendar using iCal. Copy the
            link below and follow the instructions
            <a
              target="_blank"
              class="btn-link"
              href="https://help.leavedates.com/hc/en-us/articles/360006150359"
              rel="noopener"
              >here</a
            >.
          </p>

          <div v-if="employee" class="form-group">
            <div class="tw-flex tw-items-center tw-w-full">
              <div class="tw-mr-4 tw-w-1/3 tw-font-semibold tw-text-gray-700">
                My Leave
              </div>
              <input
                :value="employee.feeds.icalendar.leave"
                :title="employee.feeds.icalendar.leave"
                class="form-control tw-mr-4 tw-w-full"
                type="text"
                autocomplete="off"
                readonly
              />
              <button
                v-clipboard:copy="employee.feeds.icalendar.leave"
                v-clipboard:success="urlCopied"
                type="button"
                title="Copy"
                class="tw-text-sm tw-text-blue-500"
              >
                <SvgIcon name="clipboard" class="tw-w-5 tw-h-5" />
              </button>
              <div class="tw-w-16 tw-flex tw-items-center">
                <ExtraInfo
                  v-if="iCalFeed.employeeICalFeed()"
                  icon="clock-outline"
                  :icon-colour="
                    iCalFeed.hasEmployeeICalFeedIssue()
                      ? 'tw-text-orange-500'
                      : 'tw-text-gray-700'
                  "
                >
                  <div class="tw-p-4 tw-w-48">
                    {{ iCalFeed.employmentICalFeedLastReadAt() }}
                  </div>
                </ExtraInfo>
              </div>
            </div>
          </div>

          <div
            v-if="employee && employee.feeds.icalendar.department_leave"
            class="form-group"
          >
            <div class="tw-flex tw-items-center tw-w-full">
              <div class="tw-mr-4 tw-w-1/3 tw-font-semibold tw-text-gray-700">
                My Department
              </div>
              <input
                :value="employee.feeds.icalendar.department_leave"
                :title="employee.feeds.icalendar.department_leave"
                class="form-control tw-mr-4 tw-w-full"
                autocomplete="off"
                type="text"
                readonly
              />
              <button
                v-clipboard:copy="employee.feeds.icalendar.department_leave"
                v-clipboard:success="urlCopied"
                type="button"
                title="Copy"
                class="tw-text-sm tw-text-blue-500"
              >
                <SvgIcon name="clipboard" class="tw-w-5 tw-h-5" />
              </button>
              <div class="tw-w-16 tw-flex tw-items-center">
                <ExtraInfo
                  v-if="iCalFeed.departmentICalFeed()"
                  icon="clock-outline"
                  :icon-colour="
                    iCalFeed.hasDepartmentICalIssue()
                      ? 'tw-text-orange-500'
                      : 'tw-text-gray-700'
                  "
                >
                  <div class="tw-p-4 tw-w-48">
                    {{ iCalFeed.departmentICalFeedLastReadAt() }}
                  </div>
                </ExtraInfo>
              </div>
            </div>
          </div>

          <div
            v-if="employee && employee.feeds.icalendar.company_leave"
            class="form-group"
          >
            <div class="tw-flex tw-items-center tw-w-full">
              <div class="tw-mr-4 tw-w-1/3 tw-font-semibold tw-text-gray-700">
                My Company
              </div>
              <input
                :value="employee.feeds.icalendar.company_leave"
                :title="employee.feeds.icalendar.company_leave"
                class="form-control tw-mr-4 tw-w-full"
                autocomplete="off"
                type="text"
                readonly
              />
              <button
                v-clipboard:copy="employee.feeds.icalendar.company_leave"
                v-clipboard:success="urlCopied"
                type="button"
                title="Copy"
                class="tw-text-sm tw-text-blue-500"
              >
                <SvgIcon name="clipboard" class="tw-w-5 tw-h-5" />
              </button>
              <div class="tw-w-16 tw-flex tw-items-center">
                <ExtraInfo
                  v-if="iCalFeed.companyICalFeed()"
                  icon="clock-outline"
                  :icon-colour="
                    iCalFeed.hasCompanyICalIssue()
                      ? 'tw-text-orange-500'
                      : 'tw-text-gray-700'
                  "
                >
                  <div class="tw-p-4 tw-w-48">
                    {{ iCalFeed.companyICalFeedLastReadAt() }}
                  </div>
                </ExtraInfo>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
    <ReviewReminder />
  </div>
</template>

<script>
import moment from 'moment-timezone'
import VSelect from 'vue-multiselect'
import FormatDate from '@/mixins/FormatDate'
import EventBus from '@/plugins/event-bus'
import Subscription from '@/mixins/Subscription'
import ValidatesForm from '@/mixins/ValidatesForm'
import MyLeaveShortcuts from '@/mixins/page-shortcuts/MyLeaveShortcuts'
import UserAvatar from '@/components/UserAvatar'
import {
  orderBy,
  isEqual,
  sortBy,
  find,
  merge,
  omit,
  reject,
  uniqBy,
  filter,
  clone,
} from 'lodash-es'
import EmployeeAllowanceCalendar from '@/components/employee-leave-allowance/EmployeeAllowanceCalendar'
import CalendarServices from '@/components/calendar-services/CalendarServices'
const LeaveSummary = () => import('@/components/LeaveSummary')
const LeaveAllowance = () => import('@/components/LeaveAllowance')
const MyLeaveCalendar = () => import('@/components/MyLeaveCalendar')
const EmployeeAllowance = () =>
  import('@/components/employee-leave-allowance/EmployeeLeaveAllowances')
const ProrateAllowanceModal = () =>
  import('@/components/employment-allowances/ProrateAllowanceModal')
const CalendarPicker = () =>
  import('@/components/calendar-picker/CalendarPicker')
const MyLeaveListTable = () =>
  import('@/components/my-leave-list/MyLeaveListTable')
const ReviewReminder = () => import('@/components/ReviewReminder')
import { Calendars, AllowanceReport, Employments, Leaves } from '@/api'
import RequestForm from '@/components/requests/RequestForm'
import SpinnerButton from '@/components/SpinnerButton'
import EmploymentsQuery from '@/graphql/queries/employments'
import LeavePermissions from '@/mixins/LeavePermissions'
import VisibleEmployments from '@/graphql/Employments'
import ExtraInfo from '@/components/ExtraInfo'
import ICalFeed from '@/models/employment/ICalFeed'

export default {
  name: 'MyYear',

  middleware: 'auth',

  components: {
    ExtraInfo,
    SpinnerButton,
    CalendarPicker,
    LeaveSummary,
    MyLeaveCalendar,
    VSelect,
    EmployeeAllowance,
    MyLeaveListTable,
    CalendarServices,
    ReviewReminder,
    ProrateAllowanceModal,
    LeaveAllowance,
    UserAvatar,
  },

  mixins: [
    ValidatesForm,
    Subscription,
    MyLeaveShortcuts,
    FormatDate,
    LeavePermissions,
  ],

  data: () => ({
    employeeFetched: false,
    calendarsFetched: false,
    loading: false,
    leaves: [],
    employments: [],
    calendars: [],
    employmentAllowances: null,
    employee: null,
    dateRange: null,
    calendarServices: [],
    currentAllowanceType: null,
    dataProrate: {
      enabled: false,
      allowanceUpdated: false,
      startDate: null,
      startDateChanged: false,
      startCalendar: null,
      endDate: null,
      endDateChanged: false,
      endCalendar: null,
    },
    iCalFeed: new ICalFeed(),
  }),

  computed: {
    availableEmployments() {
      return filter(this.employments, employment => {
        return (
          this.activeEmployment.is_admin ||
          employment.id === this.activeEmployment.id ||
          this.canApprove({ owner_id: employment.id }, this.activeEmployment)
        )
      }).map(employment => {
        return clone(employment)
      })
    },

    allowanceReportDate() {
      if (this.selectedCalendar.isPastCalendar()) {
        return this.selectedCalendar.end_date
      }

      if (this.selectedCalendar.isFutureCalendar()) {
        return this.selectedCalendar.start_date
      }

      return moment().utc()
    },

    isActiveEmployment() {
      return this.employee && this.employee.id === this.activeEmployment.id
    },

    selectableViews() {
      return [
        {
          id: 'calendar',
          name: 'Calendar',
        },
        {
          id: 'list',
          name: 'List',
        },
      ]
    },

    view: {
      get() {
        return (
          find(this.selectableViews, { id: this.$route.query.view }) ||
          this.selectableViews[0]
        )
      },
      set(view) {
        this.updateRouteWithView(view.id)
      },
    },

    allowanceModalClass() {
      return this.employmentAllowances?.length() > 1
        ? 'modal-center multi-column-modal'
        : 'modal-center single-column-modal'
    },

    employeeAllowanceCalendar() {
      return new EmployeeAllowanceCalendar(this.employee, this.calendars)
    },

    hasCustomCalendars() {
      return this.customCalendars.length !== 0
    },

    customCalendars() {
      return this.employeeAllowanceCalendar.getEmployeeCalendars()
    },

    months() {
      if (!this.selectedCalendar) return []

      let start = moment.utc(this.selectedCalendar.start_date)
      const end = moment.utc(this.selectedCalendar.end_date)
      let months = []

      while (start.isBefore(end) || start.format('M') === end.format('M')) {
        months.push(start.clone())
        start.add(1, 'month')
      }

      return months
    },

    selectedCalendar: {
      get() {
        const calendarForPeriod = this.employeeAllowanceCalendar.getCalendarForPeriod(
          this.$route.query.period
        )

        const calendarForRoute = this.customCalendars.find(calendar => {
          return calendar.id === this.$route.query.calendar
        })

        return (
          calendarForPeriod ||
          calendarForRoute ||
          this.employeeAllowanceCalendar.getCurrentYearCalendar() ||
          this.employeeAllowanceCalendar.getNearestCalendar()
        )
      },
      set(val) {
        this.updateRouteWithCalendar(val.id)
      },
    },

    visibleAllowances() {
      if (this.activeEmployment.isAdmin()) {
        return this.employmentAllowances.whereHasLimitedAllowances()
      }

      return this.employmentAllowances.whereHasAllowances()
    },

    orderedLeaveAllowances() {
      return [
        ...(this.defaultLeaveAllowance ? [this.defaultLeaveAllowance] : []),
        ...this.nonDefaultLeaveAllowances,
      ]
    },

    defaultLeaveAllowance() {
      return this.visibleAllowances.find(allowance =>
        allowance
          .allowanceType()
          .isSame(this.activeCompany.default_leave_type.allowance_type_id)
      )
    },

    nonDefaultLeaveAllowances() {
      const allowances = this.visibleAllowances.filter(
        allowance =>
          !allowance
            .allowanceType()
            .isSame(this.activeCompany.default_leave_type.allowance_type_id)
      )

      return orderBy(allowances, 'allowance_type.name')
    },

    defaultCalendar() {
      const calendars = [...this.customCalendars]

      return (
        calendars.find(calendar => {
          return moment()
            .utc(true)
            .isBetween(
              calendar.start_date.clone().startOf('day'),
              calendar.end_date.clone().endOf('day'),
              null,
              []
            )
        }) || calendars[0]
      )
    },

    isAdmin() {
      return this.activeEmployment && this.activeEmployment.is_admin
    },

    isApprover() {
      return this.activeEmployment && this.activeEmployment.is_approver
    },

    isNonAdminApprover() {
      return !this.isAdmin && this.isApprover
    },

    calendarsNotAvailableMessage() {
      if (this.isActiveEmployment) {
        return 'You have no calendars available'
      }

      return this.employee.full_name + ' has no calendars available'
    },

    contactAdministratorMessage() {
      if (this.isActiveEmployment) {
        return 'Please contact your administrator'
      }

      return 'Please contact the administrator'
    },

    canShowProrationModal() {
      return (
        this.dataProrate.enabled &&
        ((this.dataProrate.startDate && this.dataProrate.startCalendar) ||
          this.dataProrate.endDate)
      )
    },

    hasLeave() {
      return this.leaves.length > 0
    },
  },

  watch: {
    '$route.query': {
      immediate: true,
      async handler(newVal, oldVal) {
        if (newVal['show-calendar-integration']) {
          this.$nextTick(() => {
            this.$modal.show('modal-cal-integration')
          })
        }

        if (isEqual(newVal, oldVal)) return

        if (!oldVal || newVal.company !== oldVal.company) {
          await this.onChangeCompany()

          let omitParams = []

          if (newVal.calendar && !this.isValidCalendar(newVal.calendar)) {
            omitParams.push('calendar')
          }

          if (newVal['leave-request']) {
            omitParams.push('leave-request')
          }

          if (omitParams.length) {
            this.$router.push(
              {
                query: omit(this.$route.query, omitParams),
              },
              () => {}
            )

            return
          }

          if (newVal.calendar) {
            await this.fetchSelectedEmployment()
            await this.fetchExternalCalendars()
            this.onChangeCalendar(newVal, oldVal)

            return
          }
        }

        if (oldVal?.period && newVal?.period && newVal?.calendar) {
          this.$router.push(
            {
              query: omit(this.$route.query, ['period']),
            },
            () => {}
          )
        }

        if (!oldVal || !newVal.calendar) {
          this.onChangeCalendar(newVal, oldVal)
        }

        if (!oldVal) return

        if (newVal.employee !== oldVal.employee) {
          await this.fetchSelectedEmployment()
          await this.fetchExternalCalendars()
          this.onChangeCalendar(newVal, oldVal)
        }

        if (newVal.calendar && newVal.calendar !== oldVal.calendar) {
          this.onChangeCalendar(newVal, oldVal)
        }
      },
    },

    selectedCalendar: {
      immediate: true,
      async handler(newVal) {
        if (!newVal || !this.$route.query.calendar) {
          return
        }

        if (newVal.id !== this.$route.query.calendar) {
          this.updateRouteWithCalendar(newVal.id)
        }
      },
    },
  },

  created() {
    EventBus.$on(
      [
        'notification-fetched',
        'leave-requested',
        'leave-updated',
        'leave-cancelled',
        'leave-retracted',
        'overtime-requested',
        'overtime-approved',
        'overtime-updated',
        'overtime-cancelled',
        'overtime-rejected',
      ],
      () => this.fetchLeaveWithAllowances()
    )
  },

  mounted() {
    const query = this.$route.query
    if (query.error) {
      this.error(query.error)
      this.resetRoute()
    }
  },

  methods: {
    handleSynchronizationCreated() {
      this.fetchExternalCalendars()
    },

    disconnectExternalCalendar(calendar) {
      this.calendarServices = reject(this.calendarServices, calendarService => {
        return calendarService.service === calendar.service
      })
    },

    async onChangeCompany() {
      return [
        await this.getEmployments(),
        await this.fetchSelectedEmployment(),
        await this.fetchExternalCalendars(),
        await this.fetchCalendars(),
      ]
    },

    onChangeCalendar(newVal, oldVal) {
      if (!newVal.calendar) {
        this.handleRouteCalendar(newVal, oldVal)

        return
      }

      this.fetchLeaveWithAllowances()
    },

    handleRouteCalendar(newQuery, oldQuery) {
      if (
        (!this.isValidCalendar(this.$route.query.calendar) ||
          this.hasEmployeeChanged(newQuery, oldQuery)) &&
        !!this.defaultCalendar
      ) {
        this.updateRouteWithCalendar(this.defaultCalendar.id)
      }
    },

    hasEmployeeChanged(newQuery, oldQuery) {
      const newEmployee = newQuery.employee || ''
      const oldEmployee = oldQuery && oldQuery.employee ? oldQuery.employee : ''

      return newEmployee !== oldEmployee
    },

    showLeaveDetailsPanel(leave) {
      this.$router.push({
        query: {
          ...this.$route.query,
          company: this.activeCompany.id,
          'leave-request': leave.id,
        },
      })
    },

    showOvertimeDetailsPanel(overtime) {
      this.$router.push({
        query: {
          ...this.$route.query,
          company: this.activeCompany.id,
          overtime: overtime.id,
        },
      })
    },

    updateRouteWithView(view) {
      this.$router.push(
        {
          query: {
            ...this.$route.query,
            view: view,
          },
        },
        () => {}
      )
    },

    showCalendarIntegration() {
      this.$router.push({
        query: {
          ...this.$route.query,
          'show-calendar-integration': true,
        },
      })
    },

    onCloseCalendarIntegrationModal() {
      this.$router.push({
        query: {
          ...omit(this.$route.query, 'show-calendar-integration'),
        },
      })
    },

    hideCalendarIntegration() {
      this.$modal.hide('modal-cal-integration')
    },

    urlCopied() {
      this.success(`URL successfully copied to the clipboard.`)
    },

    fetchEmploymentsAndLeaveAllowances() {
      this.fetchEmployments()
      this.fetchSelectedEmployment()
      this.fetchExternalCalendars()
      this.fetchEmploymentAllowances()
    },

    showEmployeeAllowance(allowanceType) {
      this.currentAllowanceType = allowanceType
      this.fetchSelectedEmployment()
      this.$modal.show('employee-allowance-form')
    },

    fetchLeaveWithAllowances() {
      if (!this.$route.query.calendar) return

      this.fetchLeaves()
      this.fetchEmploymentAllowances()
    },

    async fetchLeaves() {
      try {
        const { data } = await Leaves.all({
          ...this.$route.query,
          status: 'requested,approved',
          owner: this.employee.id,
          within: `${this.selectedCalendar.start_date},${this.selectedCalendar.end_date}`,
        })

        this.leaves = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async fetchEmploymentAllowances() {
      try {
        this.employmentAllowances = (
          await AllowanceReport.getDetailsAllowances({
            company_id: this.activeCompany.id,
            date: this.allowanceReportDate.format('YYYY-MM-DD'),
            employee: this.employee.id,
          })
        ).items()
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async fetchCalendars() {
      this.calendarsFetched = false

      try {
        this.calendars = (await Calendars.all(this.$route.query)).all()
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.calendarsFetched = true
    },

    isValidCalendar(calendar) {
      return find(this.calendars, ['id', calendar])
    },

    getEmployments() {
      if (this.isAdmin) {
        this.fetchEmployments()
      }

      if (this.isNonAdminApprover) {
        return this.getSubordinates()
      }
    },

    async fetchEmployments() {
      try {
        await this.$apollo.queries.employments.refetch()
      } catch (error) {
        this.validateGraphQLFromResponse(error, false)
      }
    },

    getSubordinates() {
      this.employments = sortBy(
        uniqBy(
          [this.activeEmployment, ...this.activeEmployment.subordinates],
          'id'
        ),
        'full_name'
      )
    },

    updateRouteWithCalendar(calendarId) {
      this.$router.replace(
        {
          query: {
            ...this.$route.query,
            calendar: calendarId,
          },
        },
        () => {}
      )
    },

    createRequest({ start, end }) {
      start = moment.utc(start)
      // fix exclusive end date of full-calendar moment object
      end = moment
        .utc(end)
        .subtract(1, 'day')
        .endOf('day')

      this.showRequestForm([start, end])
    },

    updateEmployee(employee) {
      this.$router.push(
        {
          query: {
            ...this.$route.query,
            employee: employee.id,
          },
        },
        () => {}
      )
    },

    showRequestForm(dates = []) {
      const form = new RequestForm({
        employments: this.employments,
        success: panel => this.$showPanel(panel),
        failed: async message => {
          const confirmed = await this.confirm(message)

          if (!confirmed) return

          return this.$router.push({ name: 'billing' }, () => {})
        },
      })

      this.dateRange = [...dates]

      form.open(this.activeCompany, this.employee, this.dateRange)
    },

    async fetchSelectedEmployment() {
      this.loading = true
      this.employeeFetched = false

      try {
        const selectedEmploymentId = this.$route.query.employee
          ? this.$route.query.employee
          : this.activeEmployment.id

        const { data } = await Employments.get(selectedEmploymentId, {
          company_id: this.activeCompany.id,
        })

        this.employee = data
        this.iCalFeed = new ICalFeed(data)

        this.loading = false
        this.employeeFetched = true

        return data
      } catch ({ response }) {
        this.validateFromResponse(response, false)

        this.loading = false
        this.employeeFetched = true
      }
    },

    async fetchExternalCalendars() {
      if (!this.isActiveEmployment) {
        return
      }

      this.loading = true

      try {
        const { data } = await Employments.getCalendarServices(
          this.employee.id,
          {
            company_id: this.activeCompany.id,
          }
        )

        this.calendarServices = data

        this.loading = false
      } catch ({ response }) {
        this.validateFromResponse(response, false)

        this.loading = false
      }
    },

    setProrationData(dataProrate) {
      if (!dataProrate) {
        return
      }

      this.dataProrate = merge(this.dataProrate, dataProrate)

      this.showProrationModal()
    },

    resetProrationData() {
      this.dataProrate = {
        enabled: false,
        allowanceUpdated: false,
        startDate: null,
        startDateChanged: false,
        startCalendar: null,
        endDate: null,
        endDateChanged: false,
        endCalendar: null,
      }
    },

    showProrationModal() {
      this.dataProrate.enabled = true

      this.$nextTick(() => {
        if (!this.canShowProrationModal) {
          return
        }

        this.$modal.hide('employee-allowance-form')

        this.$modal.show('employee-allowance-proration')
      })
    },

    closeProrationModal() {
      if (this.dataProrate.allowanceUpdated) {
        this.fetchEmploymentsAndLeaveAllowances()
      }

      this.$modal.hide('employee-allowance-proration')
    },

    prorationModalClosed() {
      this.$modal.show('employee-allowance-form')

      this.resetProrationData()
    },
    resetRoute() {
      const query = { ...this.$route.query }
      delete query.error
      this.$router.replace({ query })
    },
  },

  apollo: {
    employments: {
      debounce: 50,
      query: EmploymentsQuery,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          company: this.activeCompany.id,
          employmentsQuery: {
            offset: 0,
            limit: 10000,
            employmentIds: [],
            departmentIds: [],
            orderBy: null,
          },
        }
      },
      result(response) {
        this.employments = VisibleEmployments.all(response.data.employments)
      },
      error() {},
    },
  },
}
</script>

<style>
.no-data h4 {
  @apply tw-text-gray-600;
}
</style>
